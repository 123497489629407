import * as React from "react";
import Headshot from "../images/strongbad.gif";
import "./index.css";
import * as styles from "./index.module.css";
import Layout from '../components/Layout';

export default function Index() {
  return <Layout>
      <div className={`contentLeft ${styles.contentLeft}`}>
        <div className={styles.contentLeftDiv}>
          <img src={Headshot} className={styles.headshot} alt="Colin Takeo"></img>
        </div>
      </div>
      <div className={`contentRight ${styles.contentRight}`}>
        <img src="https://content.presentermedia.com/content/animsp/00004000/4115/shoveling_hole_PA_md_nwm_v2.gif" alt="Under construction..."></img>
      </div>
  </Layout>
}
